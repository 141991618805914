@import "../../styles/sccs-vars.scss";

.page {
  max-width: 1250px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    flex: 0 0 auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;

    @media (max-width: $until-sm) {
      margin: 0;
    }

    @media (max-width: $until-lg) {
      .nav {
        display: none;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__footer {
    margin-top: 5px;
    flex: 0 0 auto;
  }
}
