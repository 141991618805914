@import "../../styles/sccs-vars.scss";

.quadrant-section {
  &__header {
    margin-bottom: 20px;
  }

  &__rings {
    display: flex;
    flex-wrap: wrap;
  }

  &__ring {
    box-sizing: border-box;
    padding: 0 8px;
    flex: 1 0 15%;
    margin: 0 0 25px;

    @media (max-width: $until-md) {
      flex-basis: 50%;
    }
  }
}
